import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';

export const defaultSweepState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    sweeps: [],
};

// REDUCERS
// basic actions that affect this object only

const getSweepReducer = (state, action) => {
    const newSweeps = action.payload;

    if (newSweeps) {
        return {
            ...state,
            lastRequested: Date.now(),
            sweeps: newSweeps,
        };
    } else {
        return state;
    }
};

const clearSweepReducer = (state, action) => {
    return defaultSweepState;
};

const setRequestErrorReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        requestError: true,
    };
};

export const sweepSlice = createSlice({
    name: 'sweep',
    initialState: defaultSweepState,
    reducers: {
        getSweeps: getSweepReducer,
        clearSweep: clearSweepReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: {
        [userSlice.actions.logout]: state => {
            return defaultSweepState;
        },
        // [entryFormSlice.actions.setEntryResponse]: state => {
        //     return defaultSweepState;
        // },
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getSweeps = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getSweeps' }));
    const result = await apiClient().getSweeps(payload);
    dispatch(sweepSlice.actions.getSweeps(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getSweeps' }));
};

//actions that get exported to the views
export const sweepActions = dispatch => {
    return {
        getSweeps: payload => getSweeps(dispatch, payload),
    };
};
