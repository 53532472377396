import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';

export const defaultDrawTicketState = {
    gameOver: false,
    success: false,
    message: '',
};

// REDUCERS
// basic actions that affect this object only

const enterDrawTicketReducer = (state, action) => {
    return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
    };
};

const resetReducer = (state, action) => {
    return {
        ...defaultDrawTicketState,
    };
};

const setGameOverReducer = (state, action) => {
    return {
        ...state,
        gameOver: action.payload.gameOver,
    };
};

export const drawTicketSlice = createSlice({
    name: 'drawTicket',
    initialState: defaultDrawTicketState,
    reducers: {
        enterDrawTicket: enterDrawTicketReducer,
        reset: resetReducer,
        setGameOver: setGameOverReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const enterDrawTicket = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'enterDrawTicket' }));
    const result = await apiClient().enterDrawTicket(payload);
    if (result.success) {
        dispatch(entrySlice.actions.setLastEntry(result.entry));
    }
    dispatch(drawTicketSlice.actions.enterDrawTicket(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'enterDrawTicket' }));
};

const setGameOver = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setGameOver' }));
    const result = await apiClient().getGameOver(payload);
    dispatch(drawTicketSlice.actions.setGameOver(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'setGameOver' }));
};

//actions that get exported to the views
export const drawTicketActions = dispatch => {
    return {
        enterDrawTicket: payload => enterDrawTicket(dispatch, payload),
        reset: payload => dispatch(drawTicketSlice.actions.reset()),
        setGameOver: payload => setGameOver(dispatch, payload),
    };
};
