import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from './Store';
import PropTypes from 'prop-types';

const ReduxWrapper = ({ element }) => {
    return <Provider store={createStore()}>{element}</Provider>;
};

ReduxWrapper.propTypes = {
    element: PropTypes.node,
};

export default ReduxWrapper;
