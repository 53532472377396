import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';

// just in case we need to style this locally
// const samplePromotions = [
//     {"promotion_id":2,"name":"Every 2 Instant Tickets","description":"Get a bonus try for every other ticket you enter.","quantum":"day","pre_qualifying_rule":null,"effective":1567101178,"expiration":1730419200,"limit_global":"0","limit_global_warn":"0","limit_player":"100","limit_player_warn":"0","reveal":null,"running":true,"user_visible":true,"rank":"0","signals":["instantticketentered"],"gauges":[]},
//     {"promotion_id":5,"name":"Every Draw Ticket","description":"Get a bonus try for every draw ticket you enter.","quantum":"day","pre_qualifying_rule":null,"effective":1564632000,"expiration":1730419200,"limit_global":"0","limit_global_warn":"0","limit_player":"1000","limit_player_warn":"0","reveal":null,"running":true,"user_visible":true,"rank":"0","signals":["instantticketentered"],"gauges":[]},
//     {"promotion_id":39,"name":"2 Tickets Win","description":"User must enter two tickets to win a prize","quantum":"unlimited","pre_qualifying_rule":"{\"objects\":[\"player\"],\"bag\":0,\"multiplier\":1}","effective":"07\/18\/2019","expiration":"12\/30\/2020","limit_global":0,"limit_global_warn":0,"limit_player":0,"limit_player_warn":0,"reveal":["57b8ece4b04fe4c906ed39ba","26d0bc167c2e88810e1e66db"],"running":true,"user_visible":true,"rank":1,"signals":["instantticketentered"],"gauges":[{"id":66,"balance":1,"trigger":2,"ceiling":0,"triggered_count":0,"name":"instant entry count","description":"","format":"integer"}]},
//     {"promotion_id":68,"name":"MDNewPrizeType","description":"This is a test using the new prize type that was designed for the BirthdayBonus promotions","quantum":"unlimited","pre_qualifying_rule":"{\"objects\":[\"player\"],\"predicate\":{\"hasdate\":[[\"<<activated\"],\"__today\",\"m\/d\"]},\"bag\":0,\"multiplier\":1}","effective":"08\/07\/2019","expiration":"08\/07\/2020","limit_global":0,"limit_global_warn":0,"limit_player":0,"limit_player_warn":0,"reveal":["57b8ece4b04fe4c906ed39ba"],"running":true,"user_visible":true,"rank":1,"signals":["temporal"],"gauges":[{"balance":0,"trigger":1,"ceiling":0,"triggered_count":0,"name":"temporal","description":"","format":"integer"}]}
// ];

export const defaultState = {
    lastRequested: null, // possibly use this to trigger reloading?
    promotions: [],
    detailId: null,
    playerPromotions: [],
};

// REDUCERS
// basic actions that affect this object only

const getPromotionsReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        promotions: action.payload,
    };
};

const getPlayerPromotionsReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        playerPromotions: action.payload,
    };
};

const setRequestErrorReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        requestError: true,
    };
};

export const promotionSlice = createSlice({
    name: 'promotion',
    initialState: defaultState,
    reducers: {
        getPromotions: getPromotionsReducer,
        getPlayerPromotions: getPlayerPromotionsReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: {
        [userSlice.actions.logout]: () => defaultState,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getPromotions = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
    const result = await apiClient().getPromotions();
    dispatch(promotionSlice.actions.getPromotions(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
};

//actions that get exported to the views
export const promotionActions = dispatch => {
    return {
        getPromotions: payload => getPromotions(dispatch, payload),
    };
};
