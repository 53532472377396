import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';

export const defaultWinnerState = {
    drawings: [],
};

// REDUCERS
// basic actions that affect this object only

const getDrawingsReducer = (state, action) => {
    return {
        drawings: action.payload,
    };
};

const resetReducer = (state, action) => {
    return {
        ...defaultWinnerState,
    };
};

export const winnerSlice = createSlice({
    name: 'winner',
    initialState: defaultWinnerState,
    reducers: {
        getDrawings: getDrawingsReducer,
        reset: resetReducer,
    },
});

//ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getDrawings = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getDrawings' }));
    const result = await apiClient().winners(payload);
    dispatch(winnerSlice.actions.getDrawings(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getDrawings' }));
};

//actions that get exported to the views
export const winnerActions = dispatch => {
    return {
        getDrawings: payload => getDrawings(dispatch, payload),
        reset: payload => dispatch(winnerSlice.actions.reset(payload)),
    };
};
