import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';

export const defaultScratchTicketState = {
    gameOver: false,
    success: false,
    message: '',
};

// REDUCERS
// basic actions that affect this object only

const enterScratchTicketReducer = (state, action) => {
    return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
    };
};

const resetReducer = (state, action) => {
    return {
        ...defaultScratchTicketState,
    };
};

const setGameOverReducer = (state, action) => {
    return {
        ...state,
        gameOver: action.payload.gameOver,
    };
};

export const scratchTicketSlice = createSlice({
    name: 'scratchTicket',
    initialState: defaultScratchTicketState,
    reducers: {
        enterScratchTicket: enterScratchTicketReducer,
        reset: resetReducer,
        setGameOver: setGameOverReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const enterScratchTicket = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'enterScratchTicket' }));
    const result = await apiClient().enterScratchTicket(payload);
    if (result.success) {
        dispatch(entrySlice.actions.setLastEntry(result.entry));
    }
    dispatch(scratchTicketSlice.actions.enterScratchTicket(result));
    dispatch(
        loadingSlice.actions.unsetLoading({ action: 'enterScratchTicket' })
    );
};

const setGameOver = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setGameOver' }));
    const result = await apiClient().getGameOver(payload);
    dispatch(scratchTicketSlice.actions.setGameOver(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'setGameOver' }));
};

//actions that get exported to the views
export const scratchTicketActions = dispatch => {
    return {
        enterScratchTicket: payload => enterScratchTicket(dispatch, payload),
        reset: payload => dispatch(scratchTicketSlice.actions.reset()),
        setGameOver: payload => setGameOver(dispatch, payload),
    };
};
