import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';
import { drawTicketSlice } from './DrawTicketStore';

export const defaultUserState = {
    player: {
        name: '',
        email: '',
        player_id: '',
        first_name: '',
        last_name: '',
        email_confirm: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone1: '',
        area_phone1: '',
        prefix_phone1: '',
        suffix_phone1: '',
        phone2: '',
        area_phone2: '',
        prefix_phone2: '',
        suffix_phone2: '',
        dob_year: '',
        dob_month: '',
        dob_day: '',
        password: '',
        verify_password: '',
        optins: '',
        optin1: '',
        optin2: '',
    },
    loggedIn: false,
    errors: '',
    success: false,
};

// REDUCERS FOR THE USER
// basic actions that affect this object only

const loginReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        loggedIn: payload.loggedIn,
        player: {
            ...state.player,
            ...payload.player,
        },
        errors: payload.errors,
    };
};

const logoutReducer = (state, action) => {
    return defaultUserState;
};

const updateReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        errors: payload.errors,
        success: payload.success,
        player: {
            ...state.player,
            ...payload.player,
        },
    };
};

const registerReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        errors: payload.errors,
        success: payload.success,
        player: {
            ...state.player,
            ...payload.player,
        },
    };
};

const forgotPasswordReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        errors: payload.errors,
        success: payload.success,
    };
};

const getPlayerDataRamReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        loggedIn: payload.loggedIn,
        player: {
            ...state.player,
            ...payload.player,
        },
    };
};

const clearErrorsReducer = (state, action) => {
    return {
        ...state,
        errors: '',
        success: false,
    };
};

export const userSlice = createSlice({
    name: 'user',
    initialState: defaultUserState,
    reducers: {
        login: loginReducer,
        logout: logoutReducer,
        update: updateReducer,
        register: registerReducer,
        getPlayerData: getPlayerDataRamReducer,
        clearErrors: clearErrorsReducer,
        forgotPassword: forgotPasswordReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const login = async (dispatch, payload) => {
    if (!userSlice.loggedIn) {
        dispatch(loadingSlice.actions.setLoading({ action: 'login' }));
        const result = await apiClient().login(payload);
        dispatch(userSlice.actions.login(result));
        dispatch(entrySlice.actions.reset());
        dispatch(drawTicketSlice.actions.reset());
        dispatch(loadingSlice.actions.unsetLoading({ action: 'login' }));
    }
};

const logout = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'logout' }));
    const result = await apiClient().logout(payload);
    dispatch(userSlice.actions.logout(result));
    dispatch(entrySlice.actions.reset());
    dispatch(drawTicketSlice.actions.reset());
    dispatch(loadingSlice.actions.unsetLoading({ action: 'logout' }));
};

const update = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'update' }));
    const result = await apiClient().update(payload);
    dispatch(userSlice.actions.update(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'update' }));
};

const register = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'register' }));
    const result = await apiClient().register(payload);
    dispatch(userSlice.actions.register(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'register' }));
};

const forgotPassword = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'forgotPasword' }));
    const result = await apiClient().forgotPassword(payload);
    dispatch(userSlice.actions.forgotPassword(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'forgotPasword' }));
};

const getPlayerData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getPlayerData' }));
    const result = await apiClient().getPlayerDataRam();
    dispatch(userSlice.actions.getPlayerData(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getPlayerData' }));
};

// actions that get exported to the views
export const userActions = dispatch => {
    return {
        login: payload => login(dispatch, payload),
        logout: payload => logout(dispatch, payload),
        update: payload => update(dispatch, payload),
        register: payload => register(dispatch, payload),
        forgotPassword: payload => forgotPassword(dispatch, payload),
        getPlayerData: payload => getPlayerData(dispatch, payload),
        clearErrors: payload => dispatch(userSlice.actions.clearErrors()),
    };
};
