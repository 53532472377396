import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';
import promotion_config from '../../promotion_config';

export const defaultEntryState = {
    entries: [],
    drawings: [],
    points: 0,
    last_entry: null,
    game_over: false,
    error: '',
};

// REDUCERS
// basic actions that affect this object only

const getEntriesReducer = (state, action) => {
    if (action.payload.entries != undefined) {
        let ents = action.payload.entries.filter(ent => {
            return ent.game_id === promotion_config.promotion_id;
        });

        let points = ents.reduce((sum, val) => {
            return sum + parseInt(val.points);
        }, 0);

        return {
            ...state,
            entries: ents,
            points: points,
            drawings: action.payload.drawings,
        };
    } else {
        return state;
    }
};

const resetReducer = (state, action) => {
    return {
        ...defaultEntryState,
    };
};

const setLastEntryReducer = (state, action) => {
    return {
        ...state,
        last_entry: action.payload,
    };
};

const clearErrorsReducer = (state, action) => {
    return {
        ...state,
    };
};

export const entrySlice = createSlice({
    name: 'entry',
    initialState: defaultEntryState,
    reducers: {
        getEntries: getEntriesReducer,
        reset: resetReducer,
        setLastEntry: setLastEntryReducer,
        clearErrors: clearErrorsReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getEntries = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
    const result = await apiClient().getEntries();
    dispatch(entrySlice.actions.getEntries(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
};

//actions that get exported to the views
export const entryActions = dispatch => {
    return {
        getEntries: payload => getEntries(dispatch, payload),
        reset: payload => dispatch(entrySlice.actions.reset(payload)),
        setLastEntry: payload =>
            dispatch(entrySlice.actions.setLastEntry(payload)),
        clearErrors: payload => dispatch(entrySlice.actions.clearErrors()),
    };
};

// TEST REDUX OBJECT FOR ENTRIES
// {
//     type: 'entry/getEntries',
//     payload: {
//         entries: [
//             {
//                 game_id: '7366',
//                 entry_id: '33',
//                 points: '5',
//                 multiplier: '1',
//                 entry_date: '2019-12-03 14:31:51',
//                 ticket_number: '7344-1254679-918 8698146695',
//                 entry_date_local_tz: '2019-12-03 13:31:51',
//                 script_id: '0',
//                 game_type: 'Instant',
//                 draw_id: '22',
//                 category: '1',
//                 draw_number: 1
//             },
//             {
//                 game_id: '7366',
//                 entry_id: '34',
//                 points: '5',
//                 multiplier: '1',
//                 entry_date: '2019-12-03 14:33:51',
//                 ticket_number: '7344-111111-918 8698146695',
//                 entry_date_local_tz: '2019-12-03 13:31:51',
//                 script_id: '0',
//                 game_type: 'Instant',
//                 draw_id: '21',
//                 category: '1',
//                 draw_number: 2
//             },
//         ],
//         drawings: [
//         {
//             id: '22',
//             game_id: '7366',
//             category: '1',
//             start_date: '2020-04-24 00:00:00',
//             end_date: '2020-05-04 12:59:59',
//             draw_date: '2020-12-14',
//             draw_status: 'current',
//             draw_number: 1
//         },
//         {
//             id: '21',
//             game_id: '7366',
//             category: '1',
//             start_date: '2020-05-05 13:00:00',
//             end_date: '2021-12-21 23:59:59',
//             draw_date: '2021-12-26',
//             draw_status: 'future',
//             draw_number: 2
//         }
//         ]
//     }
// }
