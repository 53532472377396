module.exports = {
    title: 'PA iLottery',
    promotion_id: '',
    promotion: 'pail',
    appUrl: '',
    lottoUrl: '',
    prodhost: '',
    uathost: '',
    feedbackIssueZone: 'SCBZ',
    defaultLoginLink: `https://www.pailottery.com/login/`,
    getLoginLink: (app, redirect) => (app && redirect ? `` : this.defaultLoginLink),
    registerLink: '',
    forgot: '/users/forgot',
    cms_url: 'https://pa.cms-dev-web1.scientificgames.com',
    nyxUrlsUrl: 'https://www-test-pail.nyxop.net/api/navigation/level/top',
    resolveCurrency: (name, copyProvider) => {
        const currencyExchange = [
            {
                name: 'sweepstake',
                displayName: copyProvider('currency_sweepstake'),
                showQuantity: true,
                isPence: false,
                resolve: amount => {
                    return amount;
                },
            },
            {
                name: 'lotteryproducts',
                displayName: copyProvider('currency_lotteryproducts'),
                showQuantity: false,
                isPence: false,
                resolve: amount => {
                    return '';
                },
            },
            {
                name: 'promocash',
                displayName: copyProvider('currency_promocash'),
                showQuantity: true,
                isPence: true,
                resolve: amount => {
                    const amt = amount / 100;
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: copyProvider('currency_type') }).format(amt);
                },
            },
            {
                name: 'default',
                displayName: copyProvider('currency_default'),
                showQuantity: true,
                isPence: false,
                resolve: amount => {
                    return amount;
                },
            },
        ];

        return currencyExchange.filter(item => {
            return item.name === name || item.name === 'default';
        })[0];
    },
    apiClientConfig: {
        appUrl: '',
        routes: {
            getConfig: 'loyalty2.getConfig',
            login: 'loyalty2.ptaLogin',
            logout: 'loyalty2.logout',
            checkTicket: 'loyalty2.checkTicket',
            enterDrawTicket: 'loyalty2.enterTicket',
            enterScratchTicket: 'loyalty2.enterTicket',
            enterFastPlayTicket: 'loyalty2.enterTicket',
            getEntries: 'scbzApi.getEntries',
            getPlayerDataRam: 'loyalty2.getPlayerDataRam',
            setEntryFlashGameFlag: 'scbzApi.setEntryFlashGameFlag',
            update: 'fauxScbzApi.update',
            register: 'fauxScbzApi.register',
            winners: 'loyalty2.winners',
            getFeedbackCategories: 'loyalty2.getFeedbackCategories',
            postFeedback: 'loyalty2.postFeedback',
            forgotPassword: 'fauxScbzApi.forgotPassword',
            getGame: 'fauxScbzApi.getGame',
            getGameOver: 'fauxScbzApi.getGameOver',
            getAchievements: 'loyalty2.getAchievements',
            getPoints: 'loyalty2.getPoints',
            getActivity: 'loyalty2.getActivity',
            getReward: 'loyalty2.getReward',
            getPromotions: 'loyalty2.getPromotions',
            getPlayerPromotions: 'loyalty2.getPlayerPromotions',
            getSweeps: 'loyalty2.getSweeps',
            getRevealToken: 'loyalty2.getRevealToken',
        },
    },
};
