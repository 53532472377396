import { createSlice } from 'redux-starter-kit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';

export const defaultFeedbackState = {
    success: false,
    isError: false,
    error: {},
};

// REDUCERS
// basic actions that affect this object only

const postFeedbackReducer = (state, action) => {
    return {
        ...state,
        success: action.payload.success,
        isError: action.payload.isError,
        error: action.payload.error,
    };
};

const resetReducer = (state, action) => {
    return {
        ...defaultFeedbackState,
    };
};

export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: defaultFeedbackState,
    reducers: {
        postFeedback: postFeedbackReducer,
        reset: resetReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const postFeedback = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'postFeedback' }));
    const result = await apiClient().postFeedback(payload);
    dispatch(feedbackSlice.actions.postFeedback(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'postFeedback' }));
};

//actions that get exported to the views
export const feedbackActions = dispatch => {
    return {
        postFeedback: payload => postFeedback(dispatch, payload),
        reset: payload => dispatch(feedbackSlice.actions.reset(payload)),
    };
};
