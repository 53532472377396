import { configureStore, combineReducers } from 'redux-starter-kit';
import { configSlice, configActions } from './Stores/ConfigStore';
import { userSlice, userActions } from './Stores/PTAUserStore';
import { checkTicketActions, checkTicketSlice } from './Stores/CheckTicketStore';
import { drawTicketActions, drawTicketSlice } from './Stores/DrawTicketStore';
import { scratchTicketActions, scratchTicketSlice } from './Stores/ScratchTicketStore';
import { fastPlayTicketActions, fastPlayTicketSlice } from './Stores/FastPlayTicketStore';
import { entrySlice, entryActions } from './Stores/EntryStore';
import { loadingSlice, loadingActions } from './Stores/LoadingStore';
import { winnerSlice, winnerActions } from './Stores/WinnerStore';
import { feedbackCategoriesSlice, feedbackCategoriesActions } from './Stores/FeedbackCategoriesStore';
import { feedbackSlice, feedbackActions } from './Stores/FeedbackStore';
import { revealTokenSlice, revealTokenActions } from './Stores/RevealTokenStore';
import { notificationSlice, notificationActions } from './Stores/NotificationStore';
import { achievementSlice, achievementActions } from './Stores/AchievementStore';
import { pointSlice, pointActions } from './Stores/PointsStore';
import { activitySlice, activityActions } from './Stores/ActivityStore';
import { rewardSlice, rewardActions } from './Stores/RewardStore';
import { promotionSlice, promotionActions } from './Stores/PromotionStore';
import { sweepSlice, sweepActions } from './Stores/SweepStore';

export const createStore = () => {
    return configureStore({
        reducer: combineReducers({
            config: configSlice.reducer,
            user: userSlice.reducer,
            checkTicket: checkTicketSlice.reducer,
            drawTicket: drawTicketSlice.reducer,
            scratchTicket: scratchTicketSlice.reducer,
            fastPlayTicket: fastPlayTicketSlice.reducer,
            entry: entrySlice.reducer,
            loading: loadingSlice.reducer,
            winner: winnerSlice.reducer,
            feedbackCategories: feedbackCategoriesSlice.reducer,
            feedback: feedbackSlice.reducer,
            revealToken: revealTokenSlice.reducer,
            notification: notificationSlice.reducer,
            achievement: achievementSlice.reducer,
            points: pointSlice.reducer,
            activity: activitySlice.reducer,
            reward: rewardSlice.reducer,
            promotion: promotionSlice.reducer,
            sweep: sweepSlice.reducer,
        }),
    });
};

export const actionsStore = dispatch => {
    return {
        configActions: configActions(dispatch),
        userActions: userActions(dispatch),
        checkTicketActions: checkTicketActions(dispatch),
        drawTicketActions: drawTicketActions(dispatch),
        scratchTicketActions: scratchTicketActions(dispatch),
        fastPlayTicketActions: fastPlayTicketActions(dispatch),
        entryActions: entryActions(dispatch),
        loadingActions: loadingActions(dispatch),
        winnerActions: winnerActions(dispatch),
        feedbackCategoriesActions: feedbackCategoriesActions(dispatch),
        feedbackActions: feedbackActions(dispatch),
        revealTokenActions: revealTokenActions(dispatch),
        notificationActions: notificationActions(dispatch),
        achievementActions: achievementActions(dispatch),
        pointActions: pointActions(dispatch),
        activityActions: activityActions(dispatch),
        rewardActions: rewardActions(dispatch),
        promotionActions: promotionActions(dispatch),
        sweepActions: sweepActions(dispatch),
    };
};

export const mapStateToProps = ({
    config,
    user,
    checkTicket,
    drawTicket,
    scratchTicket,
    fastPlayTicket,
    entry,
    loading,
    winner,
    feedbackCategories,
    feedback,
    revealToken,
    notification,
    achievement,
    points,
    activity,
    reward,
    promotion,
    sweep,
}) => ({
    config,
    user,
    checkTicket,
    drawTicket,
    scratchTicket,
    fastPlayTicket,
    entry,
    loading,
    winner,
    feedbackCategories,
    feedback,
    revealToken,
    notification,
    achievement,
    points,
    activity,
    reward,
    promotion,
    sweep,
});
export const mapDispatchToProps = dispatch => {
    return {
        actions: actionsStore(dispatch),
    };
};
